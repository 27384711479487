@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
/*HELPER*/
.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.b1 {
  border: .1rem solid darkred !important; }

.b2 {
  border: .1rem solid navy !important; }

.b3 {
  border: .1rem solid lime !important; }

.none {
  display: none; }

.tRight {
  text-align: right; }

.tCenter {
  text-align: center; }

.tLeft {
  text-align: left; }

.relative {
  position: relative; }

.hide {
  display: none !important; }

.relative {
  position: relative; }

.scrollY {
  overflow-y: auto !important;
  overflow-x: hidden !important; }

.grid {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
  align-content: stretch; }

/**/
/*PAGINATION*/
.pagination {
  text-align: center; }
  .pagination ul {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;
    justify-content: stretch;
    justify-content: center; }
    .pagination ul li {
      position: relative;
      margin: 0 -2px; }
      .pagination ul li a, .pagination ul li span {
        display: block;
        background: transparent;
        color: #307846;
        height: 30px;
        width: 30px;
        font-size: 1.4rem;
        line-height: 23px;
        font-weight: 700;
        cursor: pointer;
        transition: all 600ms;
        border: 4px solid #307846;
        border-radius: 100%; }
        .pagination ul li a:hover, .pagination ul li span:hover {
          background: transparent;
          color: #9BC911;
          border: 4px solid #9BC911; }
        .pagination ul li a i, .pagination ul li span i {
          margin-top: 3px; }
    .pagination ul .active {
      z-index: 99999; }
      .pagination ul .active a, .pagination ul .active span {
        color: #9BC911;
        background: transparent;
        border: 4px solid #9BC911; }
    .pagination ul .prev a, .pagination ul .next a {
      line-height: 22px !important; }

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  background: white;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
  z-index: 999999; }
  @media screen and (max-width: 960px) {
    .modal {
      width: 80%; } }
  .modal h1, .modal h2, .modal h3, .modal h4 {
    margin-top: 0; }
  .modal .modal-content {
    padding: 20px; }
  .modal .modal-close {
    cursor: pointer; }
  .modal .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 4px 6px;
    height: 56px;
    width: 100%; }
    .modal .modal-footer .btn, .modal .modal-footer .btn-flat {
      float: right;
      margin: 6px 0; }

#lean-overlay {
  position: fixed;
  z-index: 99999;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
  cursor: pointer; }

.modal.modal-fixed-footer {
  padding: 0;
  height: 70%; }
  .modal.modal-fixed-footer .modal-content {
    position: fixed;
    max-height: 100%;
    padding-bottom: 64px;
    width: 100%;
    overflow-y: auto; }
  .modal.modal-fixed-footer .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0; }

.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: auto;
  border-radius: 0;
  will-change: bottom, opacity; }

